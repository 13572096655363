import React, { useState, useEffect, useRef, Fragment } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import identityverificationObj from "../../services/identityverification.service";
import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import DialoguePopup from "../../helpers/DialoguePopup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BoLoader from "../../helpers/BoLoader";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import crossimg from "../../assets/images/icons/cross.svg";
import fileuploadimg from "../../assets/images/icons/file-upload.svg";
import RefreshToken from "../../helpers/RefreshToken";
import Radio from "../../helpers/Radio";
import ReactFlagsSelect from "react-flags-select";
import * as EmailValidator from "email-validator";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { Link } from "react-router-dom";
import PreviewTheme from "./PreviewTheme";
import { TrimCharacters } from "../../helpers/TrimCharacters";

let obj = [];
let currencycodes = [{
  id: "EUR",
  value: "EUR"
}, { id: "GBP", value: "GBP" }, { id: "USD", value: "USD" }, { id: "CAD", value: "CAD" }];

let defaultCurrency = currencycodes.filter(i => i.value === "USD")

const AddClient = props => {
  return (
    <ModelPopup
      show={props.show}
      onHide={props.onHide}
      modelheading={""}
      showheader={"false"}
      backdrop="static"
      keyboard={false}
      className="smallPopup issueCardSmall "
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76 "
      modelbody={
        <AddClients
          show={props.show}
          onHide={props.onHide}
          onAddClient={props.onAddClient}
        />
      }
    />
  );
};
const AddClients = props => {
  const CompanyNameRequired = value => {
    if (!value) {
      document.getElementById("companyName").classList.add("has_error");
      return <span className="help-block">Company Name is required!</span>;
    } else document.getElementById("companyName").classList.remove("has_error");
  };
  const EmailRequired = value => {
    if (!value) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const checkValidEail = emailAdress => {
    let value = EmailValidator.validate(emailAdress);
    return value;
  };
  const EmailFormatRequired = value => {
    let verifyemailcheck = checkValidEail(value);
    if (!verifyemailcheck) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Please enter valid email!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const ReferenceEmailFormatRequired = value => {
    if (value) {
      let verifyemailcheck = checkValidEail(value);
      if (!verifyemailcheck) {
        document.getElementById("reference_email").classList.add("has_error");
        return <span className="help-block">Please enter valid email!</span>;
      } else document.getElementById("reference_email").classList.remove("has_error");
    }
  };
  const AddressRequired = value => {
    if (!value) {
      document.getElementById("id_addressClient").classList.add("has_error");
      return <span className="help-block">Address is required!</span>;
    } else
      document.getElementById("id_addressClient").classList.remove("has_error");
  };
  const CityRequired = value => {
    if (!value) {
      document.getElementById("id_cityClient").classList.add("has_error");
      return <span className="help-block">City is required!</span>;
    } else
      document.getElementById("id_cityClient").classList.remove("has_error");
  };
  const ZipCodeRequired = value => {
    if (!value) {
      document.getElementById("id_zipCodeClient").classList.add("has_error");
      return <span className="help-block">Zip Code is required!</span>;
    } else
      document.getElementById("id_zipCodeClient").classList.remove("has_error");
  };
  const CheckNegativePrefund = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_prefundamount").classList.add("has_error");
      return (
        <span className="help-block">Prefunded Amount cannot be negative!</span>
      );
    } else
      document.getElementById("id_prefundamount").classList.remove("has_error");
  };
  const PrefundRequired = value => {
    if (!value) {
      document.getElementById("id_prefundamount").classList.add("has_error");
      return <span className="help-block">PreFund Amount is required!</span>;
    } else
      document.getElementById("id_prefundamount").classList.remove("has_error");
  };
  const checkPhoneNumberLength = value => {
    if (value.length < 4) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      return (
        <span className="help-block">Minimum three Numbers are required!</span>
      );
    } else
      document.getElementById("id_phonenumber").classList.remove("has_error");
  };
  const required = value => {
    if (!value) {
      return <span className="help-block">this field is required!</span>;
    }
  };
  const requiredNationality = value => {
    if (!value) {
      document.getElementById("id_nationality").classList.add("has_error");
      setshowNationalityErr("");
      return false;
    } else {
      document.getElementById("id_nationality").classList.remove("has_error");
      setshowNationalityErr("d-none");
      return true;
    }
  };
  const requiredTheme = value => {
    if (!value) {
      document.getElementById("id_theme").classList.add("has_error");
      setshowThemeErr("");
      return false;
    } else {
      document.getElementById("id_theme").classList.remove("has_error");
      setshowThemeErr("d-none");
      return true;
    }
  };
  const pointOfContactRequired = value => {
    if (!value) {
      document.getElementById("id_pointofcontact").classList.add("has_error");
      return <span className="help-block">Point of Contact is required!</span>;
    } else
      document
        .getElementById("id_pointofcontact")
        .classList.remove("has_error");
  };
  const primaryColorRequired = value => {
    if (!value) {
      document.getElementById("id_primColor").classList.add("has_error");
      return (
        <span className="help-block">Primary Colour Code is required!</span>
      );
    } else
      document.getElementById("id_primColor").classList.remove("has_error");
  };
  const secondaryColorRequired = value => {
    if (!value) {
      document.getElementById("id_secColor").classList.add("has_error");
      return (
        <span className="help-block">Secondary Colour Code is required!</span>
      );
    } else document.getElementById("id_secColor").classList.remove("has_error");
  };
  const cardLimitRequired = value => {
    if (!value) {
      document.getElementById("id_cardLimit").classList.add("has_error");
      return <span className="help-block">Card Limit is required!</span>;
    } else
      document.getElementById("id_cardLimit").classList.remove("has_error");
  };
  const checkCardLimitNegative = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_cardLimit").classList.add("has_error");
      return <span className="help-block">Card Limit cannot be negative!</span>;
    } else
      document.getElementById("id_cardLimit").classList.remove("has_error");
  };
  const form = useRef();
  const checkBtn = useRef();
  const [ClientName, setName] = useState("");
  const [Mobile, setphoneNumber] = useState("");
  const [PrefundAmount, setprefundedAmount] = useState();
  const [Email, setemail] = useState("");
  const [RefrenceEmail, setRefrenceEmail] = useState("");
  const [PointOfContact, setpointOfContract] = useState("");
  const [LogoName, setlogoName] = useState("");
  const [LogoUrl, setlogoUrl] = useState("");
  const [PermissionsList, setPermissionsList] = useState([]);
  const [ClientStatusId, setClientStatusId] = useState(1);
  const [StatusList, setStatusList] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [Role, SetRole] = useState("");
  const [LogoSize, setlogoSize] = useState();
  const [isValidFile, setisValidFile] = useState(true);
  const [Address, setAddress] = useState("");
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
  //Select Payment Processor
  const [PaymentProcessorId, setPaymentProcessorId] = useState(2);
  //Select Card Flow
  const [CardFlow, setCardFlow] = useState("DEBIT");
  //Select Account Type
  const [AccountType, setAccountType] = useState("External");
  //Select MSwipe Issuer
  const [IssuerCategory, setIssuerCategory] = useState("IssuerB");
  //Select Card type visa,Master or Both
  const [CardVisaMaster, setCardVisaMaster] = useState("");

  //Set Card Limit
  const [CardLimit, setCardLimit] = useState();

  //Register User
  const [Username, setUsername] = useState("");
  const [Name, setNameRegister] = useState("");
  const [Permissions, setPermissions] = useState([]);
  const [Rolename, SetRolename] = useState("");

  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");

  //loader
  const [boloader, setBoLoader] = useState(false);
  //Themes
  const [ThemesList, setThemesList] = useState([]);
  const [SelectedTheme, setSelectedTheme] = useState("");
  const [ThemeId, setThemeId] = useState();
  //Nium API Fields
  //const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [showNationalityErr, setshowNationalityErr] = useState("d-none");
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  const [showPhoneMinimumErr, setshowPhoneMinimumErr] = useState("d-none");

  const [showDOBErr, setShowDOBErr] = useState("d-none");
  const [showThemeErr, setshowThemeErr] = useState("d-none");
  //Theming Colour Selection Codes
  const [PrimaryColorCode, setPrimaryColorCode] = useColor("hex", "#121212");
  const [SecondaryColorCode, setSecondaryColorCode] = useColor(
    "hex",
    "#121212"
  );
  const [colorlight1, setColorlight1] = useColor("hex", "#121212");
  const [colorlight2, setColorlight2] = useColor("hex", "#121212");
  const [colortype, setColortype] = useState();

  //Theming palate selection
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalPreviewShow, setModalPreviewShow] = useState(false);
  //Client ledger currency
  const [ClientCurrency, setClientCurrency] = useState("USD");
  const [currencylists, setCurrencylists] = useState(defaultCurrency);

  const requiredDob = value => {
    if (value.length === 0) {
      document.getElementById("dob-frmgrp").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dob-frmgrp").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
    }
  };
  const requiredPhoneNo = value => {
    if (Mobile.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("id_phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };
  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
    var t = object.target.value;
    if (t.includes("."))
      object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  };
  useEffect(() => {
    clientmanagmentObj
      .getRoles()
      .then(response => {
        if (response && response.data && response.data.payload.result) {
          const lst_roles = response.data.payload.result.map(data => {
            if (data.name !== "Super admin" || data.name !== "Card holder") {
              return {
                value: data.id,
                label: data.name,
              };
            }
          });
          lst_roles.shift();
          lst_roles.shift();
          lst_roles.shift();

          setRoleList(lst_roles);
          SetRolename(lst_roles[0].label);
        }
      })
      .catch(error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  }, []);
  useEffect(() => {
    clientmanagmentObj
      .getStatuses()
      .then(response => {
        if (response && response.data && response.data.payload) {
          const lst_statuses = response.data.payload.result.map(data => {
            return {
              value: data.id,
              label: data.name,
            };
          });
          setStatusList(lst_statuses);
        }
      })
      .catch(error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  }, []);
  useEffect(() => {

    clientmanagmentObj
      .getPermissions("client", 0,0)
      .then(response => {
        if (response && response.data && response.data.payload) {
          const lst_permissions = response.data.payload.result.map(data => {
            return {
              id: data.id,
              claimName: data.claimName,
              uuid: data.uuid,
              checked: true,
            };
          });
          setPermissionsList(lst_permissions);
        }
      })
      .catch(error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  }, []);
  useEffect(() => {
    clientmanagmentObj
      .getThemes()
      .then(response => {
        if (response && response.data && response.data.payload) {
          const lst_themes = response.data.payload.result.map(data => {
            return {
              id: data.id,
              lable: data.themeName,
              value: data.themeLabels,
            };
          });
          setThemesList(lst_themes);
        }
      })
      .catch(error => {
        // (async () => {
        //   let resp = await RefreshToken(
        //     error && error.response && error.response.status
        //   );
        //   if (resp) {
        //     window.location.reload();
        //   }
        // })();
      });
  }, []);
  useEffect(() => {
    setisValidFile(true);
  }, [setlogoSize]);
  useEffect(() => {
    if (Mobile.length === 0) {
      setisValidPhoneNumber(true);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else if (Mobile.length < 3) {
      setisValidPhoneNumber(false);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else {
      setisValidPhoneNumber(true);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    }
  }, [Mobile]);

  const onChangename = e => {
    const ClientName = e.target.value;
    setName(ClientName);
    setNameRegister(ClientName);
  };
  const onChangePrefundedAmount = e => {
    let PrefundAmount = e.target.value;
    if (PrefundAmount != "") {
      let value = parseFloat(PrefundAmount);
      setprefundedAmount(value);
    }
  };
  const onChangeCardLimit = e => {
    const CardLimit = e.target.value;
    if (CardLimit != "") {
      let value = parseFloat(CardLimit);
      setCardLimit(value);
    }
  };
  const onChangerole = e => {
    const role = e.target.selectedOptions[0].text;
    const rolename = e.target.selectedOptions[0].text;
    SetRole(role);
    SetRolename(rolename);
  };
  // const onChangePaymentProcessor = e => {
  //   const id = e.target.selectedOptions[0].value;
  //   let value = parseFloat(id);
  //   setPaymentProcessorId(value);
  //   if (value === 2) {
  //     let currency = currencylists.filter(i => i.value === "USD");
  //     setCurrencylists(currency);
  //     setClientCurrency("USD");
  //     setIssuerCategory("IssuerS");
  //   }
  //   else if (value === 1) {
  //     let currency = currencycodes.filter(i => i.value === "EUR");
  //     setCurrencylists(currencycodes);
  //     setClientCurrency("EUR");
  //     setIssuerCategory("");

  //   }

  // };
  const filterPermissionOnIssuer = (issuer) => {
    if (issuer === "IssuerB") {
      obj = Permissions.filter(
        i => { return (i.ClaimType !== "Issue Physical Card" || i.ClaimType === "Activate Physical Card") }
      );
      setPermissions(obj);
    }
    else if (issuer === "IssuerG") {
      obj = Permissions.filter(
        i => { return (i.ClaimType !== "Issue Virtual Card" || i.ClaimType === "User KYC") }
      );
      setPermissions(obj);
    }
    else if (issuer === "IssuerS") {
      obj = Permissions.filter(
        i => {return(i.ClaimType !== "Issue Physical Card" || i.ClaimType === "Activate Physical Card")}
      );
      setPermissions(obj);
    }
    else if (issuer === "IssuerSP") {
      obj = Permissions.filter(
        i => {return (i.ClaimType !== "Issue Virtual Card")}
      );
      setPermissions(obj);
    }


  }

  const onChangeMswipeIssuer = e => {
    setBoLoader(true);
    const name = e.target.selectedOptions[0].value;
    setIssuerCategory(name);
    filterPermissionOnIssuer(name);
    if (name === "IssuerS") {
      setCardFlow("DEBIT");
      let currency = currencycodes.filter(i => i.value === "EUR" || i.value === "GBP" || i.value === "USD");
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("Both");

    }
    else if (name === "IssuerB") {
      let currency = currencycodes.filter(i => i.value === "USD")
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("");
    }
    else if (name === "IssuerG") {
      let currency = currencycodes.filter(i => i.value === "USD")
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("");
    }
    else if (name === "IssuerB4B") {
      let currency = currencycodes.filter(i => i.value === "EUR" || i.value === "GBP" || i.value==="USD")
      setCurrencylists(currency);
      setClientCurrency("EUR");
      setCardVisaMaster("");
    }
    else if (name === "IssuerW") {
      let currency = currencycodes.filter(i => i.value === "EUR")
      setCurrencylists(currency);
      setClientCurrency("EUR");
      setCardVisaMaster("");
    }
    else if (name === "IssuerB4Virtual") {
      let currency = currencycodes.filter(i => i.value === "EUR" || i.value === "GBP"|| i.value === "USD")
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("");
    }
    else if (name === "IssuerSP") {
      let currency = currencycodes.filter(i => i.value === "USD")
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("");
    }
    else if (name === "IssuerM") {
      let currency = currencycodes.filter(i => i.value === "GBP")
      setCurrencylists(currency);
      setClientCurrency("GBP");
      setCardVisaMaster("");
    }
    else if (name === "IssuerI") {
      let currency = currencycodes.filter(i => i.value === "USD")
      setCurrencylists(currency);
      setClientCurrency("USD");
      setCardVisaMaster("");
    }
    setBoLoader(false);

  };
  const onChangeCardFlow = e => {
    const id = e.target.selectedOptions[0].value;
    setCardFlow(id);
  };
  const onChangeAccountType = e => {
    const id = e.target.selectedOptions[0].value;
    setAccountType(id);
  };
  const onChangeEmail = e => {
    const Email = e.target.value;
    setemail(Email);
    setUsername(Email);
  };
  const onChangeReferenceEmail = e => {
    const Email = e.target.value;
    setRefrenceEmail(Email);
  };
  const onChangePointOfContract = e => {
    let value = (e.target.value)
    value = value.replace(/[^a-z0-9]/gi, '')
    setpointOfContract(value);
  };
  const onChangeaddress = e => {
    const Address = e.target.value;
    setAddress(Address);
  };
  // const onChangestatus = (e) => {
  //   const status = e.target.selectedOptions[0].value;
  //   //const status = e.value;
  //   let statusvalue = parseInt(status);
  //   setClientStatusId(statusvalue);
  // };
  const onChangeLogo = e => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFile(false);
      return false;
    }
    setlogoName(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSize(size);
    formdata.append("File", file_data);
    identityverificationObj
      .uploaduserdocs(formdata)
      .then(response => {
        let name = response.data.payload;
        setlogoUrl(name);
      })
      .catch(error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const saveUser = e => {
    e.preventDefault();
    form.current.validateAll();
    let nationality = requiredNationality(CountryCode);
    let permissionValid = requiredDob(Permissions);
    //let themeselected = requiredTheme(ThemeId);
    let phoneValid = requiredPhoneNo(Mobile);

    if (
      checkBtn.current.context._errors.length === 0 &&
      phoneValid &&
      nationality &&
      permissionValid &&
      // themeselected &&
      isValidPhoneNumber
    ) {
      setBoLoader(true);
      clientmanagmentObj
        .addClient(
          ClientName,
          Mobile,
          Email,
          PrefundAmount,
          PointOfContact,
          LogoUrl,
          ClientStatusId,
          Permissions,
          Rolename,
          Address,
          ThemeId,
          City,
          ZipCode,
          CountryCode,
          PaymentProcessorId,
          CardLimit,
          PrimaryColorCode.hex ? PrimaryColorCode.hex : PrimaryColorCode,
          SecondaryColorCode.hex ? SecondaryColorCode.hex : SecondaryColorCode,
          colorlight1.hex ? colorlight1.hex : colorlight1,
          colorlight2.hex ? colorlight2.hex : colorlight2,
          ClientCurrency, RefrenceEmail, CardFlow, IssuerCategory, AccountType, CardVisaMaster
        )
        .then(response => {
          setBoLoader(false);
          if (response && response.data) {
            if (response.data?.success) {
              // history.push("clients");
              // window.location.reload();
              props.onAddClient();
            }
          }
        })
        .catch(error => {
          setBoLoader(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Error Occured while Registering client";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            let resp = await RefreshToken(
              error && error.response && error.response.status
            );
            if (resp) {
              if (resp) {
                saveUser();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            }
          })();
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };

  const handleSingleCheck = e => {
    if (e.target.checked === true) {
      obj = obj.concat({
        UserId: e.target.id,
        ClaimType: e.target.attributes.claimtype.value,
        ClaimValue: e.target.attributes.claimvalue.value,
      });
      setPermissions(obj);
    }
    if (e.target.checked === false) {
      obj = Permissions.filter(
        i => i.ClaimType !== e.target.attributes.claimtype.value
      );
      setPermissions(obj);
    }
    requiredDob(obj);
  };

  const uploadFileAddClient = e => {
    document.getElementById("AddClientFileUpload").click();
  };
  const onCrossImg = () => {
    setlogoName("");
    setisValidFile(true);
  };
  const onChangeTheme = e => {
    const themeid = parseInt(e.target.attributes.idtheme.value);
    const value = e.target.attributes.idLable.value;
    setThemeId(themeid);
    setSelectedTheme(value);
    requiredTheme(themeid);
  };
  const onChangeCity = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z]/ig, '')
    setCity(value);
  };

  const onChangePrimaryColor = e => {
    const color = e.target.value;
    setPrimaryColorCode(color);
  };
  const onChangeSecondaryColor = e => {
    const color = e.target.value;
    setSecondaryColorCode(color);
  };
  const onChangeLightColor1 = e => {
    const color = e.target.value;
    setColorlight1(color);
  };
  const onChangeLightColor2 = e => {
    const color = e.target.value;
    setColorlight2(color);
  };

  const onChangeZipCode = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setZipCode(value);
  };
  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setphoneNumber(e);
  };
  const previewTheme = (e) => {
    let localhostUrl = "http://localhost:3003/previewtheme";
    let deployedUrl = "https://dev.card-console.com/previewtheme";
    let stagingUrl = "https://card-console.com/previewtheme";

    let url = window.location.href;
    if (url.includes("localhost")) url = localhostUrl;
    else if (url.includes("dev")) url = deployedUrl;
    else
      url = stagingUrl;
    let pri = PrimaryColorCode.hex ? PrimaryColorCode.hex : PrimaryColorCode;
    if (pri.includes("#"))
      pri = pri.substring(1);
    let sec = SecondaryColorCode.hex ? SecondaryColorCode.hex : SecondaryColorCode
    if (sec.includes("#"))
      sec = sec.substring(1);
    let lcf = colorlight1.hex ? colorlight1.hex : colorlight1;
    if (lcf.includes("#"))
      lcf = lcf.substring(1);
    let lcs = colorlight2.hex ? colorlight2.hex : colorlight2;
    if (lcs.includes("#"))
      lcs = lcs.substring(1);
    window
      .open(
        url +
        "?pri=" +
        pri +
        "&sec=" +
        sec + "&lcf=" + lcf + "&lcs=" + lcs
      )
      .focus();
    //setModalPreviewShow(true);
  };
  const openModalcolor = type => {
    let e = {
      hex: "#151515",
      hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 },
      rgb: {
        a: undefined,
        b: 21,
        g: 21,
        r: 21,
      },
    };
    if (type === "pri") setPrimaryColorCode(e);
    else if (type === "sec") setSecondaryColorCode(e);
    else if (type === "lc1") setColorlight1(e);
    else if (type === "lc2") setColorlight2(e);

    setColortype(type);
    setModalsuccessfullShow(true);
  };
  const closeModalcolor = () => {
    console.log(colorlight1);
  };
  const onChangeClientCurrency = e => {
    const name = e.target.selectedOptions[0].value;
    setClientCurrency(name);
    if (name === "EUR" || name === "GBP")
      setCardVisaMaster("");
  }
  const onChangeCardVisaMaster = (e) => {
    const name = e?.target?.selectedOptions[0]?.value;
    setCardVisaMaster(name);
  }
  return (
    <div className="">
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Create Client</h2>
          <div className="mt-24">
            <Form onSubmit={(e) => e.preventDefault()} ref={form}>
              <div className="row">
                <div className="col-md-6" id="companyName">
                  <div className="form-group">
                    <label>Company Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        onChange={onChangename}
                        validations={[CompanyNameRequired, required]}
                        value={ClientName}
                        maxLength="80"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="email">
                  <div className="form-group">
                    <label>Email*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onChange={onChangeEmail}
                        validations={[
                          EmailRequired,
                          required,
                          EmailFormatRequired,
                        ]}
                        value={Email}
                        maxLength="50"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6" id="id_phonenumber" >
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <div className="form-group">
                      <PhoneInput
                        className=""
                        country={"us"}
                        onChange={onChangePhoneNumber}
                        value={Mobile}
                      />
                      <span className={"help-block " + showPhoneErr}>
                        Phone No is required!
                      </span>
                      {!isValidPhoneNumber && (
                        <div id="id_minimumphonelength">
                          <span className={"help-block " + showPhoneMinimumErr}>
                            Minimum three number are required!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_pointofcontact">
                  <div className="form-group">
                    <label>Point of Contact*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="phoneNumber"
                        value={PointOfContact}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        onChange={onChangePointOfContract}
                        maxLength="20"
                        validations={[pointOfContactRequired]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_prefundamount">
                  <div className="form-group">
                    <label>Pre Funded Amount</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        maxLength="9"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        className="form-control no-icon"
                        name="prefundedAmount"
                        value={PrefundAmount}
                        onChange={onChangePrefundedAmount}
                        validations={[CheckNegativePrefund]}
                      />
                      <span className=""></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group" id="id_nationality">
                    <label>Nationality*</label>
                    <div className="form-controls">
                      <ReactFlagsSelect
                        // countries={[
                        //   "GB","AT","BE", "BG",
                        //   "CY", "CZ","DE","DK",
                        //   "EE","ES", "FI",
                        //   "FR","GR","HR","HU",
                        //   "IE","IT", "LT","LU",
                        //   "LV","MT","NL","PO",
                        //   "PT","RO","SE","SI","SK",
                        // ]}
                        className="form-control no-icon"
                        selected={CountryCode}
                        onSelect={code => {
                          setCountryCode(code);
                          requiredNationality(code);
                        }}
                        searchable={true}
                      />
                      <span className={"help-block " + showNationalityErr}>
                        Nationality is required!
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6" id="id_addressClient">
                  <div className="form-group">
                    <label>Address*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control left-icon"
                        onChange={onChangeaddress}
                        value={Address}
                        validations={[AddressRequired]}
                        maxLength="40"
                      />
                      <span className="icon-home _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_cityClient" >
                  <div className="form-group">
                    <label>City*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={City}
                        onChange={onChangeCity}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkAlpha(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }

                        }
                        validations={[CityRequired]}
                        maxLength="20"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_zipCodeClient">
                  <div className="form-group">
                    <label>ZIP Code*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        onChange={onChangeZipCode}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        value={ZipCode}
                        validations={[ZipCodeRequired]}
                        maxLength="10"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Select Role</label>
                    <div className="form-controls">
                      <select
                        onChange={onChangerole}
                        className="form-control no-icon"
                      >
                        {RoleList.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-6" id="id_payProcessor">
                  <div className="form-group">
                    <label>Payment Processor*</label>
                    <div className="form-controls">
                      <select
                        /*onChange={onChangePaymentProcessor}*/
                        className="form-control no-icon"
                      >
                        { /*<option select="selected" value={1}>
                          Nium
                        </option>*/}
                        <option value="MSwipe">
                          BO
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {
                  PaymentProcessorId === 2 && (
                    <div className="col-md-6" id="id_payProcessor">
                      <div className="form-group">
                        <label>Issuer</label>
                        <div className="form-controls">
                          <select
                            onChange={onChangeMswipeIssuer}
                            className="form-control no-icon"
                          >
                            <option value={"IssuerB"}>
                              Issuer B
                            </option>
                            {/* <option select="selected" value={"IssuerS"}>
                              Issuer S
                            </option> */}
                            <option select="selected" value={"IssuerG"}>
                              Issuer G
                            </option>
                            <option select="selected" value={"IssuerB4B"}>
                              Issuer B4B
                            </option>
                            {/* <option select="selected" value={"IssuerW"}>
                              Issuer W
                            </option>
                            <option select="selected" value={"IssuerSP"}>
                              Issuer SP
                            </option> */}
                            <option select="selected" value={"IssuerB4Virtual"}>
                              Issuer B4B Virtual
                            </option>
                            <option select="selected" value={"IssuerM"}>
                              Issuer M
                            </option>
                            <option select="selected" value={"IssuerI"}>
                              Issuer I
                            </option>
                            <option select="selected" value={"IssuerT"}>
                              Issuer T
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>)}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Client Ledger Currency*</label>
                    <div className="form-controls">
                      <select
                        className="form-control no-icon"
                        onChange={onChangeClientCurrency}
                      >
                        {currencylists.map((options) => (
                          <option key={options.id} value={options.value} >
                            {options.value}
                          </option>
                        ))}

                      </select>
                    </div>
                  </div>
                </div>
                {
                   (IssuerCategory==="IssuerS" || IssuerCategory==="IssuerB") && ClientCurrency === "USD" && (
                    <div className="col-md-6" id="id_cardType">
                      <div className="form-group">
                        <label>Network</label>
                        <div className="form-controls">
                          <select
                            onChange={onChangeCardVisaMaster}
                            className="form-control no-icon"
                          >
                            <option select="selected" value={"Both"}>
                              Both
                            </option>
                            <option value={"Visa"}>
                              Visa
                            </option>
                            <option select="selected" value={"Master"}>
                              MC
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                  )
                }

                {
                  (PaymentProcessorId === 2 && IssuerCategory === "IssuerB") && (
                    <div className="col-md-6" id="id_cardflow">
                      <div className="form-group">
                        <label>Card Flow</label>
                        <div className="form-controls">
                          <select
                            onChange={onChangeCardFlow}
                            className="form-control no-icon"
                          >
                            <option select="selected" value={"DEBIT"}>
                              Debit Flow
                            </option>
                            <option value={"CREDIT"}>
                              Credit Flow
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="col-md-6" id="id_primColor">
                  <div className="form-group">
                    <label>Primary Colour*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        value={PrimaryColorCode.hex}
                        onChange={onChangePrimaryColor}
                        //validations={[primaryColorRequired]}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("pri")}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_secColor">
                  <div className="form-group">
                    <label>Secondary Colour*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeSecondaryColor}
                        value={SecondaryColorCode.hex}
                        // validations={[secondaryColorRequired]}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("sec")}
                      ></span>
                    </div>




                  </div>
                </div>
                <div className="col-md-6" id="id_lightColor1">
                  <div className="form-group">
                    <label>Lighter Colour 1*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control "
                        onChange={onChangeLightColor1}
                        value={colorlight1.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("lc1")}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_lightColor2">
                  <div className="form-group">
                    <label>Lighter Colour 2*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control "
                        onChange={onChangeLightColor2}
                        value={colorlight2.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("lc2")}
                      ></span>
                    </div>
                  </div>
                  <div >
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16  rounded-2">Preview Theme</button>
                  </div>
                </div>
                <div className="col-md-6" id="id_cardLimit">
                  <div className="form-group">
                    <label>Card Inventory*</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        maxLength="10"
                        onInput={maxLengthCheck}
                        className="form-control no-icon"
                        name="cardLimit"
                        onWheel={(e) => e.target.blur()}
                        value={CardLimit}
                        onChange={onChangeCardLimit}
                        validations={[checkCardLimitNegative, cardLimitRequired]}
                      />
                    </div>
                  </div>
                </div>

                {LogoName === "" ? (
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label>Attach Logo</label>
                      <div className="form-controls">
                        <div>
                          <Input
                            type="file"
                            hidden
                            id="AddClientFileUpload"
                            className="form-control"
                            accept=".gif,.jpg,.jpeg,.png"
                            name="logo"
                            onChange={onChangeLogo}
                          />
                          <h5
                            className="form-control file-control"
                            onClick={uploadFileAddClient}
                          >
                            <label>Drag files here to upload or </label> Browse
                            file
                          </h5>
                          {!isValidFile && (
                            <span className="help-block has_error">
                              Invalid file format
                            </span>
                          )}
                        </div>
                        <span className="icon-upload-file _ico">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                      </div>
                      {LogoName !== "" && (
                        <label className="mt-3 mb-0">{LogoName}</label>
                      )}
                      <span className="fs-12 clr-submarine mt-2 d-block">
                        Supported file types JPEG/PNG file.
                        Recommended resolutions size is ( 500 * 500 )
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex">
                        <div className="file-name">
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                          {/* <img src={fileuploadimg} alt="" /> */}
                          <h5>
                            <span>{LogoName}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size" onClick={onCrossImg}>
                          <span>{LogoSize}</span>
                          <img
                            className="cross-icon"
                            src={crossimg}
                            onClick={onCrossImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6" id="id_accounttype">
                  <div className="form-group">
                    <label>Account Type</label>
                    <div className="form-controls">
                      <select
                        onChange={onChangeAccountType}
                        className="form-control no-icon"
                      >
                        <option select="selected" value={"ExternalAccounts"}>
                          External
                        </option>
                        <option value={"InternalAccounts"}>
                          Internal
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6" id="reference_email">
                  <div className="form-group">
                    <label>Reference Email</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onChange={onChangeReferenceEmail}
                        validations={[
                          ReferenceEmailFormatRequired,
                        ]}
                        value={RefrenceEmail}
                      />
                    </div>
                    <span className="fs-12 clr-submarine mt-2 d-block">
                        Note: only for clients with custom emails.
                      </span>
                  </div>
                  
                </div> */}
                <div className="col-md-12" id="dob-frmgrp">
                  <label>Permissions*</label>
                  <br />
                  <br />
                  <br />

                  <ul className="checkbox-block-ul mt--24">
                    {PermissionsList.map(PermissionsList => {
                      if (IssuerCategory === "IssuerB" && (PermissionsList.claimName === "Issue Physical Card" || PermissionsList.claimName === "Activate Physical Card"))
                        return
                      else if (IssuerCategory === "IssuerG" && (PermissionsList.claimName === "Issue Virtual Card" || PermissionsList.claimName === "User KYC"))
                        return
                      else if (IssuerCategory === "IssuerS" && (PermissionsList.claimName === "Issue Physical Card" || PermissionsList.claimName === "Activate Physical Card"))
                        return
                      else if (IssuerCategory === "IssuerSP" && (PermissionsList.claimName === "Issue Virtual Card"))
                        return
                      else 
                      {
                        return (
                          <li>
                            <div className="bo-checkbox bo-checkbox-round">
                              <input
                                key={PermissionsList.uuid}
                                onChange={handleSingleCheck}
                                type="checkbox"
                                id={PermissionsList.uuid}
                                ClaimType={PermissionsList.claimName}
                                ClaimValue={PermissionsList.claimName}
                              />
                              <label htmlFor={PermissionsList.uuid}>
                                {PermissionsList.claimName}
                              </label>
                            </div>
                          </li>
                        );
                      }

                    })}
                  </ul>
                  <span className={"help-block " + showDOBErr}>
                    Permission is required!
                  </span>
                </div>

                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                    <a
                      className="btn btn-outline mr-19"
                      data-bs-dismiss="modal"
                      onClick={props.onHide}
                    >
                      Cancel
                    </a>
                    <a className="btn btn-large" onClick={saveUser}>
                      Add Client
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            {colortype === "pri" ? (
              <ColorPicker
                width={300}
                height={190}
                color={PrimaryColorCode}
                onChange={setPrimaryColorCode}
                hideHSV
                dark
              />
            ) : colortype === "sec" ? (
              <ColorPicker
                width={300}
                height={190}
                color={SecondaryColorCode}
                onChange={setSecondaryColorCode}
                hideHSV
                dark
              />
            ) : colortype === "lc1" ? (
              <ColorPicker
                width={300}
                height={190}
                color={colorlight1}
                onChange={setColorlight1}
                hideHSV
                dark
              />
            ) : colortype === "lc2" ? (
              <ColorPicker
                width={300}
                height={190}
                color={colorlight2}
                onChange={setColorlight2}
                hideHSV
                dark
              />
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div className="form-group submit-field mt-40 mb-0">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  onClick={() => {
                    setModalsuccessfullShow(false);
                    closeModalcolor();
                  }}
                >
                  Done
                </Link>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={2}
        show={modalPreviewShow}
        onHide={() => setModalPreviewShow(false)}
        modelheading={""}
        showheader={"false"}
        className=""
        dialogClassName=""
        // backdrop="static"
        // keyboard={false}
        modelbody={[
          <Fragment>
            <PreviewTheme
              primary={PrimaryColorCode.hex}
              secondary={SecondaryColorCode.hex}
              colorlight1={colorlight1.hex}
              colorlight2={colorlight2.hex}

            />
            <div className="col-md-12">
              <div className="form-group submit-field mt-40 mb-0">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  onClick={() => {
                    setModalPreviewShow(false);
                  }}
                >
                  Done
                </Link>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default AddClient;
