import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.ClientAdmin; //"http://localhost:8000/api/client/";
const API_URL_Roles = Env.ClaimRoleAuth; //"http://localhost:8000/api/ClaimRoleAuth/";
const API_URL_Deposits = Env.DepositApiUrl; //"http://localhost:8000/api/deposits/";
const API_URL_Transaction = Env.TransactionApiUrl; //"http://localhost:8000/api/transaction/";
const API_URL_CARDHOLDER = Env.CardHolderApiUrl; //"http://localhost:8000/api/transaction/";
const API_IBAN = Env.IBANApiUrl; 

const addClient = (
  ClientName,
  Mobile,
  Email,
  PrefundAmount,
  PointOfContact,
  LogoUrl,
  ClientStatusId,
  Permissions,
  Rolename,
  Address,
  ThemeId,
  City,
  ZipCode,
  CountryCode,
  PaymentProcessorId,
  CardLimit,
  PrimaryColorCode,
  SecondaryColorCode,
  ColorPrimarylighter1,
  ColorPrimarylighter2,
  CurrencyCode,
  RefrenceEmail,
  CardFlow,IssuerCategory,AccountType,CardVisaMaster
) => {
  return axios
    .post(
      API_URL + "addclient",
      {
        ClientName,
        Mobile,
        Email,
        PrefundAmount,
        PointOfContact,
        LogoUrl,
        ClientStatusId,
        Permissions,
        Rolename,
        Address,
        ThemeId,
        City,
        ZipCode,
        CountryCode,
        PaymentProcessorId,
        CardLimit,
        PrimaryColorCode,
        SecondaryColorCode,
        ColorPrimarylighter1,
        ColorPrimarylighter2,
        CurrencyCode,
        RefrenceEmail,
        CardFlow,IssuerCategory,AccountType,CardVisaMaster

      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const registerClient = (ClientId, Email, Username, Name, Rolename) => {
  return axios
    .post(
      API_URL + "registerclient",
      {
        ClientId,
        Email,
        Username,
        Name,
        Rolename,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getRoles = () => {
  return axios
    .get(API_URL_Roles + "roles", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getPermissions = (role, clientId,superclientid) => {
  return axios
    .get(API_URL_Roles + "claims?role=" + role + "&clientId=" + clientId+"&superclientid=" + superclientid, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getThemes = () => {
  return axios
    .get(API_URL + "gettheme", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getStatuses = () => {
  return axios
    .get(API_URL + "getallclientstatus", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const searhGrid = (name, ClientId, dateFrom, dateTo, startIndex, Status,TokenId,IssuerType,SuperClientId) => {
  return axios
    .post(
      API_URL + "getclients",
      {
        name,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
        TokenId,
        IssuerType,
        SuperClientId
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridDebit = (name, tokenId,ClientId, dateFrom, dateTo, startIndex, Status, userId,TransactionType,FetchAll) => {
  
  return axios
    .post(
      API_URL_Transaction + "gettransaction",
      {
        name,
        tokenId,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
        TransactionType,
        FetchAll
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const updateclient = (
  id,
  ClientName,
  Email,
  address,
  Mobile,
  pointOfContact,
  PrefundAmount,
  clientStatusId,
  City,
  ZipCode,
  CountryCode,
  PrimaryColorCode,
  SecondaryColorCode,
  ColorPrimarylighter1,
  ColorPrimarylighter2,
  LogoUrl,
  Permissions, RefrenceEmail,CardLimit,CardVisaMaster
) => {
  return axios
    .post(
      API_URL + "updateclient",
      {
        id,
        ClientName,
        Email,
        address,
        Mobile,
        pointOfContact,
        PrefundAmount,
        clientStatusId,
        City,
        ZipCode,
        CountryCode,
        PrimaryColorCode,
        SecondaryColorCode,
        ColorPrimarylighter1,
        ColorPrimarylighter2,
        LogoUrl,
        Permissions, RefrenceEmail,CardLimit,CardVisaMaster
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getprofiledetails = (clientId) => {
  return axios
    .post(
      API_URL + "getclientprofiledetail?clientId=" + clientId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};

const getclientcount = () => {
  return axios
    .get(API_URL + "getclientcount", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};

//Deposits
const getdeposits = (
  name,
  tokenId,
  dateFrom,
  dateTo,
  startIndex,
  ClientId,
  methodName,
  url
) => {
  return axios
    .post(
      API_URL_Deposits + "getdeposits",
      {
        name,
        tokenId,
        dateFrom,
        dateTo,
        startIndex,
        ClientId,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};

const depositamount = (
  RequestId,
  AccountNumber,
  Bank,
  DepositDate,
  DepositAmount,
  WireConfirmationUrl,
  ClientId
) => {
  return axios
    .post(
      API_URL_Deposits + "depositamount",
      {
        RequestId,
        AccountNumber,
        Bank,
        DepositDate,
        DepositAmount,
        WireConfirmationUrl,
        ClientId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getAllClients = () => {
  return axios
    .get(API_URL + "getclientDD", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getSettingRolesClient = (userId) => {
  return axios
    .get(API_URL + "getclientclaims?userId=" + userId, {
      headers: authHeader(),
    })
    .then((resp) => {
      return resp;
    });
};
const suspendClient = (userId,status) => {
  return axios
    .post(
      API_URL + "suspendunsuspendclient?userId=" + userId+ "&status=" + status,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const unSuspendClient = (userId,status) => {
  return axios
    .post(
      API_URL + "suspendunsuspendclient?userId=" + userId+ "&status=" + status,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const clientAmountTransfer = (
  Amount,
  SenderClientId,
  ReceiverClientId,
  Description,
) => {
  return axios
    .post(
      API_URL + "clientamounttransfer",
      {
        Amount,
        SenderClientId,
        ReceiverClientId,
        Description,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const searhGridChangePin = (name, ClientId, dateFrom, dateTo, startIndex, Status) => {
  return axios
    .post(
      API_URL_CARDHOLDER + "getchangepinrequests",
      {
        name,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const pinChangeNotify = (userId, isNotify) => {
  return axios
    .post(
      API_URL_CARDHOLDER + "pinchangenotify?userId=" + userId + "&isNotify=" + isNotify,
      {

      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getChangePinCount = (ClientId,
  Status,
  dateFrom,
  dateTo,
  name,
  startIndex,) => {
  return axios
    .post(
      API_URL_CARDHOLDER + "getchangepinrequests",
      {
        ClientId,
        Status,
        dateFrom,
        dateTo,
        name,
        startIndex,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getIssuebMainBalance = () => {
  return axios
    .get(API_URL_Transaction + "getissueBmainbalance", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
//API Call for Data Older than 3 months !!!
const gettransactionhistory = (name, tokenId,ClientId, dateFrom, dateTo, startIndex, Status, userId,TransactionType,FetchAll) => {
  
  return axios
    .post(
      API_URL_Transaction + "gettransactionhistory",
      {
        name,
        tokenId,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
        TransactionType,
        FetchAll
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getAllAccounts = () => {
  return axios
    .get(API_IBAN + "getAccountsDD", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const getAllBenificary = () => {
  return axios
    .get(API_IBAN + "getBenificaryDD", { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const clientmanagmentObj = {
  addClient,
  registerClient,
  getRoles,
  getPermissions,
  getStatuses,
  searhGrid,
  updateclient,
  getprofiledetails,
  getclientcount,
  getdeposits,
  depositamount,
  getAllClients,
  getSettingRolesClient,
  getThemes,
  suspendClient,
  unSuspendClient,
  clientAmountTransfer,
  searhGridDebit,
  searhGridChangePin,
  pinChangeNotify,
  getChangePinCount,
  getIssuebMainBalance,
  gettransactionhistory,
  getAllAccounts,
  getAllBenificary
  
};
export default clientmanagmentObj;
